<template>
<div>
    <div v-if="isLoading">
      <CanPayLoader/>
    </div>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center"><label class="usernamelabel"> Financial Institutions </label></div>
    </div>
    <div v-if="financial_institutions.length!=0">
      <div v-for="(fin, index) in financial_institutions" :key="index">
        <div class="col-12">
          <div class="row bank-name-list m-2">
            <div class="col-8 align-self-center bl-f-weight text-left">
              <span>{{ fin.institution_name }}</span>
            </div>
            <div class="col-4 align-self-center">
              <div class="text-center">
                <span @click="confirmDeleteFI(fin)" title="Delete Financial Institution"> 
                  <label
                    class="delete-bank-label float-right"
                  ></label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="one mx-auto history-div" v-else>
      <span class="success-text">No Financial Institutions Found.</span>
    </div>
    
    <b-modal
        ref="confirm-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="confirm-modal"
        centered
      >
        <div class="color">
          <div class="col-12 text-center">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="120"
              height="120"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              fill="#e14343"
            >
              <path
                d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
              />
            </svg>
          </div>
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                Do you want to delete this Financial Institution?
              </label>
            </div>
            <br />
            <br />

            <div class="row justify-content-center mt-3">
              <div class="col-10 col-md-8 row">
                <div class="col-6">
                  <button
                    @click="hideModal('confirm-modal')"
                    type="button"
                    class="profile-btn-cancel w-100"
                  >
                    <span class="forgetpassword-ok-label">Cancel</span>
                  </button>
                </div>
                <div class="col-6">
                  <button
                    @click="checkPendingTransaction()"
                    type="button"
                    class="profile-btn-ok w-100"
                  >
                    <span class="forgetpassword-ok-label">Confirm</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </b-modal>
    <b-modal
        ref="delete-alert-msg-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="delete-alert-msg-modal"
        centered
      >
        <div class="color">
          <div class="col-12 text-center">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="120"
              height="120"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              fill="#e14343"
            >
              <path
                d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
              />
            </svg>
          </div>
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                Your primary account cannot be deleted while you have pending transactions. Pending transactions take up to 4 banking days to fully clear the banking system. You may see the debit to your account, however it will still take an additonal 2 days to clear the banking system. After all transactions have cleared, you may login and delete your bank account at that time.
              </label>
            </div>
            <br />
            <br />
            <div class="row">
              <div class="col-12 text-center">
                <button
                  @click="hideModal('delete-alert-msg-modal')"
                  class="btn btn-danger btn-md center-block tip-btn"
                >
                  <label class="forgetpassword-ok-label">OK</label>
                </button>
              </div>
            </div>
          </div>
        </div>
    </b-modal>
    <b-modal
      ref="fin-ins-delete-success-modal"
      hide-footer
      no-close-on-backdrop
      modal-backdrop
      hide-header
      id="fin-ins-delete-success-modal"
      centered
    >
      <div class="color">
        <div class="col-12 text-center">
          
        </div>
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              Financial Institution deleted successfully
            </label>
          </div>
          <br />
          <br />
          <div class="row">
            <div class="col-12 text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="closeMsgModal()"
              >
                <span class="purchasepower-modal-ok-label"
                  >OK</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</div>
</template>
<script>
import api from "../../api/account.js";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
export default {
  name: "FinancialInstitutions",
  components: {
    Loading,
    CanPayLoader
  },
  data() {
    return {
      isLoading: false,
      financial_institutions:{},
      institution_id:"",
      institution_login_id:""
    };
  },
  created() {
    var self = this;
    this.currentUser = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;

    if (localStorage.getItem("consumer_token") == null) {
      self.$router.push("/login");
    }else{
      self.getFinancialInstitutions();
    }
  },
  methods: {
    getFinancialInstitutions(){
      var self = this;
      self.isLoading = true;
      api
        .getFinancialInstitutions()
        .then((response) => {
          self.financial_institutions = response.data;
          self.isLoading = false;
        })
        .catch(function (error) {
          self.isLoading = false;
        });
    },
    confirmDeleteFI(fin){
      var self = this;
      self.institution_id = fin.institution_id;
      self.institution_login_id = fin.institution_login_id;
      self.$refs["confirm-modal"].show();
    },
    checkPendingTransaction(){
      var self = this;
      self.$refs["confirm-modal"].hide();
      var request = {
        institution_id: self.institution_id,
      };
      api
        .getPendingTransactionReturns(request)
        .then((response) => {
          if(response.data == 0){
            self.deleteFI();
          }else{
            self.$refs["delete-alert-msg-modal"].show();
          }
        })
        .catch(function (error) {
        });
    },
    deleteFI(){
      var self = this;
      self.$refs["delete-alert-msg-modal"].hide();
      self.isLoading = true;
      var request = {
        institution_id: self.institution_id,
        institution_login_id: self.institution_login_id
      };
      api
        .deleteFinancialInstitution(request)
        .then((response) => {
          if(response.code == 200){
            self.$refs["fin-ins-delete-success-modal"].show();
            self.getFinancialInstitutions();
          }
          self.isLoading = false;
        })
        .catch(function (error) {
          self.isLoading = false;
        });
    },
    hideModal(modal) {
      this.$refs[modal].hide();
    },
    closeMsgModal(){
      var self = this;
      self.$refs["fin-ins-delete-success-modal"].hide();
    },
  },
  mounted() {
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#149240");
    }
    this.$root.$emit("loginapp", [""]);
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
  },
};
</script>

<style lang="scss">
.b-button {
  background-color: transparent;
}
#confirm-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#delete-alert-msg-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#fin-ins-delete-success-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
